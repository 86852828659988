import React, { useState, useEffect } from "react";

/* ---------- css ---------- */
import "bootstrap/dist/css/bootstrap.css";
import "./Footer.css";

/* ---------- images ---------- */
// import cubeLogo from "../../resources/3d-cube.svg";
// import cloudSurfLogo from "../../resources/logos/cloud-logo-black.svg";

function Footer(props) {
  return (
    <React.Fragment>
      <div className="footer-container font-face-gm">
        <div className="footer-wrapper">
          {/* <img src={cloudSurfLogo} className="footer-company-icon"></img> */}
          {/* <p className="footer-company-name">CloudSurf Software</p> */}

          <div className="footer-icons">
            <a href="https://instagram.com/zachtaylor.art/" target="_blank">
              <i className="fa fa-instagram footer-icon"></i>
            </a>
          </div>
          {/* footer-icons */}
          <p className="footer-text">&copy; 2023 Zachary Taylor</p>
          <p className="footer-text">
            Powered by{" "}
            <a href="https://cloudsurfsoftware.com/" target="_blank">
              Cloudsurf
            </a>{" "}
          </p>
        </div>
        {/* footer-wrapper */}
      </div>
      {/* footer-container */}
    </React.Fragment>
  );
}

export default Footer;
