import React, { useEffect, useState } from "react";
import "./Home.css";
import Nav from "../Nav/Nav";
import Footer from "../Footer/Footer";
import galleryData from "../../util/galleryData";
import { Link, useNavigate } from "react-router-dom";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";
import fingerprintlite from "../../resources/zach-fingerprint-lite.PNG";

const Home = () => {
  const navigate = useNavigate();
  const collections = galleryData.collections;
  console.log("collections", collections);
  const [cols, setCols] = useState([]);
  const [pieceLimits, setPieceLimits] = useState([]);
  const [currPieceIds, setCurrPieceIds] = useState([]);

  const collectionRoutes = [
    { id: 0, title: "18+" },
    { id: 1, title: "Bill Posters" },
    { id: 2, title: "Rubberneck" },
    { id: 3, title: "Backslider" },
  ];
  // const [collec]

  useEffect(() => {
    console.log("currPieceIds", currPieceIds);
    console.log("pieceLimits", pieceLimits);
  }, [currPieceIds]);

  useEffect(() => {
    if (collections) {
      let lims = [];
      for (let i in collections) {
        lims.push(collections[i].pieces.length);
      }
      setPieceLimits(lims);
      setCols(collections);

      const interval = setInterval(() => {
        let newPieceIds = [];
        console.log("in interval");
        for (let i = 0; i < pieceLimits.length; i++) {
          console.log("in interval", pieceLimits[i]);

          let newId = Math.floor(Math.random() * pieceLimits[i]);
          console.log("new id:", newId);

          newPieceIds.push(newId);
          console.log("new:");
        }

        setCurrPieceIds([15, 5, 3, 7]);
      }, 2000);
      return () => clearInterval(interval);
    }
  }, [collections, setCurrPieceIds]);

  const handlePieceClick = (pieceId, collectionId) => {
    navigate(`/portfolio?collection=${collectionId}&piece=${pieceId}`);
  };

  return (
    <div>
      <div className="home-container">
        <Link to={`/portfolio?collection=0`}>
          <div className="img-container">
            <img src={fingerprintlite} className="fingerprint" />
          </div>
        </Link>

        {/* <Nav showNavTitle={false} /> */}
        <div
          style={{
            minHeight: "80vh",
            marginTop: 20,

            // marginTop: "25vh",

            display: "flex",
            // justifyContent: "space-evenly",
            flexDirection: "column",
            alignItems: "center",
            color: "white",
          }}
          className="home-container"
        >
          {/* <h1 className="font-face-gm">Zachary Taylor</h1> */}
          {/* <h2
            // style={{ fontSize: "12pt" }}
            style={{
              fontSize: "14pt",
              paddingTop: 15,
            }}
            className="font-face-gm"
          >
            Fine-art • Education • Consultation
          </h2> */}

          {/* <div
          style={{
            display: "flex",
            flexDirection: "row",
            // margin: 20,
            justifyContent: "center",
            alignItems: "center",
            // overflow: "scroll",
            width: "100%",
          }}
        >
          {cols &&
            currPieceIds &&
            cols.map(
              (item, colIndex) => (
                // cols[colIndex].pieces.map((piece, pieceIndex) => (
                <img
                  style={{ height: 200, width: "auto" }}
                  className="home-portfolio-img"
                  src={item.pieces[0].images[0].url}
                  onClick={() => handlePieceClick(0, colIndex)}
                />
              )
              // ))
            )}
        </div> */}
          {collectionRoutes.map((collection) => {
            return (
              <Link
                key={collection.id}
                to={`/portfolio?collection=${collection.id}`}
                style={{
                  display: "flex",
                  textDecoration: "none",
                  color: "black",
                  border: "2px solid white",
                  backgroundColor: "white",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "180px",
                  padding: "10px",
                  fontFamily: "Cygnito Mono",
                  fontWeight: "600",
                  fontSize: "15px",
                  marginTop: 20,
                  marginBottom: 15,
                }}
              >
                <span>{collection.title}</span>
                {/* <MdChevronRight size={"30px"} /> */}
              </Link>
            );
          })}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Home;
