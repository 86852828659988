import React, { useCallback, useState, useRef } from "react";
// import { Controlled as ControlledZoom } from 'react-medium-image-zoom'
// import "react-medium-image-zoom/dist/styles.css";
// import InnerImageZoom from 'react-inner-image-zoom';
// import "./styles.css";
import ScrollContainer from "react-indiana-drag-scroll";

function PieceImage({ src }) {
  //   const imagecontainer = useRef()
  const [zoomamount, setZoomamount] = useState(1);
  function zoom(el) {
    if (el.type === "in") {
      setZoomamount((prev) => prev + 0.1);
    } else {
      if (zoomamount > 1) {
        setZoomamount((prev) => prev - 0.1);
      }
    }
  }

  return (
    <>
      <button onClick={() => zoom({ type: "in" })}>Zoom</button>
      <button onClick={() => zoom({ type: "out" })}>Zoom out</button>

      <div className="overflowimgcontainer">
        <ScrollContainer className="grabbercontainer" hideScrollbars={true}>
          <img
            style={{
              width: 60 * zoomamount + "%",
              height: 50 * zoomamount + "%",
            }}
            src={src ? src : "https://static.toiimg.com/photo/72975551.cms"}
            alt=""
          />
        </ScrollContainer>
      </div>
    </>
  );
}

export default PieceImage;
