import logo from "./logo.svg";
import Home from "../src/components/Home/Home.js";
import "./App.css";
import React from "react";
import Portfolio from "./components/Portfolio/Portfolio";

function App() {
  return <Home />;
}

export default App;
