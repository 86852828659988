import React from "react";
import "./About.css";
import Nav from "../Nav/Nav";
import Footer from "../Footer/Footer";

// import michaelImg from "../../resources/michael.jpg";
import gilImg from "../../resources/zach-taylor.jpeg";
// import jpImg from "../../resources/jp.webp";
// import LottieTextSection from "../LottieTextSection/LottieTextSection";
// import moneyStack from "../../resources/lotties/money-stack.json";
// import safeDiamond from "../../resources/lotties/safe-diamond.json";
// import InfoAction from "../InfoAction/InfoAction";

// import ScrollAnimation from "react-animate-on-scroll";

function About(props) {
  return (
    <React.Fragment>
      <Nav />
      <div className="container team-container">
        {/* <h1 className="my-4 text-center mb-5">Zachary Taylor</h1> */}
        {/* <ScrollAnimation animateIn="fadeIn" duration={2}> */}
        <div className="position-relative overflow-hidden text-center text-light team-wrapper">
          <div className="row" style={{ marginBottom: 100 }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div className="category-img-container-2">
                <img
                  src={gilImg}
                  alt="Category"
                  className="category-img-2"
                  style={{ marginTop: "5px" }}
                />
              </div>
              {/* <a
                href="https://www.linkedin.com/in/gilsilberman/"
                className="fa fa-linkedin social"
                target={"_blank"}
              ></a> */}
              {/* <h2 className="about-job-header">Zachary Taylor</h2> */}
              <p className="team-text">
                {/* <p className="font-face-gm"> */}
                Zach Taylor was born in Las Vegas, NV where he lived for
                eighteen years of his childhood. He is a photographer and
                mixed-media artist who employs a pop art surrealist style.
              </p>
              <p className="team-text">
                For years he has constructed fantasy worlds that discuss power,
                dominance, and wealth as ingrained human desires. Mainly
                exploring the topic of masculinity in a patriarchal world, his
                work challenges societal norms of gender defined privilege.
                Visually, his work represents his childhood subconscious; a
                vault of sexually charged advertisements.
              </p>
              <p className="team-text">
                Graduating Magna Cum Laude in 2018 from DePauw University, IN,
                he received a BA in both Studio Art and Creative Writing. There
                he received numerous awards including the Fritz Smith Memorial
                Art Award and the Efroymson Summer Research Grant. While
                attending his university, he worked as the digital photography
                and film studio assistant, as well as the graphic designer for
                the theater department.
              </p>
              <p className="team-text">
                Currently living in Santa Fe, NM, he works for the Santa Fe
                Photographic Workshops. There he is employed as an educator and
                assistant. Working along side established photographers, he
                helps guide students through the digital photographic process
                including printing, editing, and organization. On the side of
                his occupation, he continues to create art and freelance. During
                his time in New Mexico, he has exhibited work in galleries
                including Ellsworth Gallery and Gold Leaf Gallery. He is
                currently a mentor for the Monte del Sol Charter School, and has
                helped create a monthly critique group of local artists.
              </p>

              <a
                className="about-download-button"
                target="_blank"
                href="https://media-hosting-bucket.s3.us-west-2.amazonaws.com/zach-taylor/zach-taylor-cv.pdf"
              >
                <p>Download CV</p>
              </a>
            </div>
          </div>
        </div>
        {/* </ScrollAnimation> */}
        <Footer />
      </div>
      {/* <InfoAction /> */}
    </React.Fragment>
  );
}

export default About;
