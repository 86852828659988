import React, { useEffect, useRef, useState } from "react";
import "./Portfolio.css";
import {
  MdChevronLeft,
  MdKeyboardArrowDown,
  MdKeyboardArrowUp,
  MdChevronRight,
} from "react-icons/md";

const PortfolioItemAbout = ({
  collectionTitle,
  collectionDescription,
  pieceTitle,
  pieceDescription,
  pieceYear,
  handleCollectionLeftClick,
  collectionScrollRef,
  collections,
  collectionIndex,
  handleCollectionRightClick,
  handlePieceClick,
}) => {
  const [showGallery, setShowGallery] = useState(false);

  const [showCollectionDescription, setShowCollectionDescription] = useState(
    false
  );
  const handleShowGalleryClick = () => {
    setShowCollectionDescription(false);
    setShowGallery(!showGallery);
  };
  const handlePieceClick2 = (id) => {
    handlePieceClick(id);
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  };

  const handleShowCollectionDescriptionClick = () => {
    setShowGallery(false);
    setShowCollectionDescription(!showCollectionDescription);
  };
  return (
    <div className="portfolio-about-container">
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <div style={{ margin: 10 }}>
            <div
              className="about-collection-button"
              onClick={() => handleShowCollectionDescriptionClick()}
            >
              <p>About</p>
              {showCollectionDescription ? (
                <MdKeyboardArrowUp size={30} display="inline" />
              ) : (
                <MdKeyboardArrowDown size={30} display="inline" />
              )}
            </div>
          </div>

          <div style={{ margin: 10 }}>
            <div
              className="about-collection-button"
              onClick={() => handleShowGalleryClick()}
            >
              <p>Gallery</p>
              {showGallery ? (
                <MdKeyboardArrowUp size={30} display="inline" />
              ) : (
                <MdKeyboardArrowDown size={30} display="inline" />
              )}
            </div>
          </div>
        </div>

        {showCollectionDescription && (
          <div
            style={{
              margin: 20,
              // display: "flex",
              // flexDirection: "column",
              // justifyContent: "center",
              // alignItems: "center",
            }}
          >
            <p>{collectionDescription}</p>
          </div>
        )}

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          {showGallery &&
            collections[collectionIndex].pieces.map((item, index) => (
              <img
                // style={{ height: 120, width: "auto" }}
                style={{
                  // margin: 20,
                  height: 120,
                  width: "auto",
                  // display: "flex",
                  // flexDirection: "column",
                  // justifyContent: "center",
                  // alignItems: "center",
                }}
                className="portfolio-item-about-img"
                src={item.images[0].url}
                onClick={() => handlePieceClick2(index)}
              />
            ))
          /* conditional ends heree  */
          }
        </div>
      </div>
    </div>
  );
};

export default PortfolioItemAbout;
