export default {
  collections: [
    {
      title: "18+",
      description: `“18+” is a series of photographs spotlighting objects in suggestive situations. These images are inspired by production advertisements I saw throughout my childhood in Las Vegas, Nevada. By illuminating an item through long exposure light painting techniques, a theatrical spectacle is made of an inanimate object acting as the entertainer. “18+” being a reference to the object performing in front of an “adult only” audience. The images are connotative of desire shown through a lens of innocence; walking the line between mature awareness of sexuality, and childhood ignorance.`,
      pieces: [
        {
          title: "Fuzzy Seat",
          year: "2018",
          description: "Description of piece Fuzzy Seat here",
          images: [
            {
              description: "Description here for image 1",
              url: "https://media-hosting-bucket.s3.us-west-2.amazonaws.com/zach-taylor/18-plus/fuzzy-seat.jfif",
            },
            {
              description: "Description here for image 2",
              url: "https://media-hosting-bucket.s3.us-west-2.amazonaws.com/zach-taylor/18-plus/fuzzy-seat.jfif",
            },
          ],
        },
        {
          title: "Bowling Ball",
          year: "2018",
          description: "Description of piece Fuzzy Seat here",

          images: [
            {
              description: "Description for image 1 here",
              url: "https://media-hosting-bucket.s3.us-west-2.amazonaws.com/zach-taylor/18-plus/bowling-ball.jfif",
            },
            {
              description: "Description for image 2 here",
              url: "https://media-hosting-bucket.s3.us-west-2.amazonaws.com/zach-taylor/18-plus/bowling-ball.jfif",
            },
          ],
        },
        {
          title: "Meat Grinder",
          year: "2018",
          description: "Description of piece Fuzzy Seat here",

          images: [
            {
              description: "Description for image 1 here",
              url: "https://media-hosting-bucket.s3.us-west-2.amazonaws.com/zach-taylor/18-plus/meat-grinder.jfif",
            },
            {
              description: "Description for image 2 here",
              url: "https://media-hosting-bucket.s3.us-west-2.amazonaws.com/zach-taylor/18-plus/meat-grinder.jfif",
            },
          ],
        },
        {
          title: "Male Mannequin",
          year: "2018",
          description: "Description of piece Fuzzy Seat here",

          images: [
            {
              description: "Description for image 1 here",
              url: "https://media-hosting-bucket.s3.us-west-2.amazonaws.com/zach-taylor/18-plus/male-mannequin.jfif",
            },
            {
              description: "Description for image 2 here",
              url: "https://media-hosting-bucket.s3.us-west-2.amazonaws.com/zach-taylor/18-plus/male-mannequin.jfif",
            },
          ],
        },
        {
          title: "Keytar",
          year: "2018",
          description: "Description of piece Fuzzy Seat here",

          images: [
            {
              description: "Description for image 1 here",
              url: "https://media-hosting-bucket.s3.us-west-2.amazonaws.com/zach-taylor/18-plus/keytar.jfif",
            },
            {
              description: "Description for image 2 here",
              url: "https://media-hosting-bucket.s3.us-west-2.amazonaws.com/zach-taylor/18-plus/keytar.jfif",
            },
          ],
        },
        {
          title: "Drip",
          year: "2018",
          description: "Description of piece Fuzzy Seat here",

          images: [
            {
              description: "Description for image 1 here",
              url: "https://media-hosting-bucket.s3.us-west-2.amazonaws.com/zach-taylor/18-plus/drip.jpg",
            },
            {
              description: "Description for image 2 here",
              url: "https://media-hosting-bucket.s3.us-west-2.amazonaws.com/zach-taylor/18-plus/drip.jpg",
            },
          ],
        },
        {
          title: "Electric Banana",
          year: "2018",
          description: "Description of piece Fuzzy Seat here",

          images: [
            {
              description: "Description for image 1 here",
              url: "https://media-hosting-bucket.s3.us-west-2.amazonaws.com/zach-taylor/18-plus/electric-banana.jpg",
            },
            {
              description: "Description for image 2 here",
              url: "https://media-hosting-bucket.s3.us-west-2.amazonaws.com/zach-taylor/18-plus/electric-banana.jpg",
            },
          ],
        },
        {
          title: "Whipped",
          year: "2018",
          description: "Description of piece Fuzzy Seat here",

          images: [
            {
              description: "Description for image 1 here",
              url: "https://media-hosting-bucket.s3.us-west-2.amazonaws.com/zach-taylor/18-plus/whipped.jpg",
            },
            {
              description: "Description for image 2 here",
              url: "https://media-hosting-bucket.s3.us-west-2.amazonaws.com/zach-taylor/18-plus/whipped.jpg",
            },
          ],
        },
        {
          title: "Chicks & Coke",
          year: "2018",
          description: "Description of piece Fuzzy Seat here",

          images: [
            {
              description: "Description for image 1 here",
              url: "https://media-hosting-bucket.s3.us-west-2.amazonaws.com/zach-taylor/18-plus/chicks-%26-coke.jpg",
            },
            {
              description: "Description for image 2 here",
              url: "https://media-hosting-bucket.s3.us-west-2.amazonaws.com/zach-taylor/18-plus/chicks-%26-coke.jpg",
            },
          ],
        },
        {
          title: "Just A Slice",
          year: "2018",
          description: "Description of piece Fuzzy Seat here",

          images: [
            {
              description: "Description for image 1 here",
              url: "https://media-hosting-bucket.s3.us-west-2.amazonaws.com/zach-taylor/18-plus/just-a-slice.jpg",
            },
            {
              description: "Description for image 2 here",
              url: "https://media-hosting-bucket.s3.us-west-2.amazonaws.com/zach-taylor/18-plus/just-a-slice.jpg",
            },
          ],
        },
        {
          title: "Rocket Launch",
          year: "2018",
          description: "Description of piece Fuzzy Seat here",

          images: [
            {
              description: "Description for image 1 here",
              url: "https://media-hosting-bucket.s3.us-west-2.amazonaws.com/zach-taylor/18-plus/rocket-launch.jpg",
            },
            {
              description: "Description for image 2 here",
              url: "https://media-hosting-bucket.s3.us-west-2.amazonaws.com/zach-taylor/18-plus/rocket-launch.jpg",
            },
          ],
        },
        {
          title: "Sunday",
          year: "2018",
          description: "Description of piece Fuzzy Seat here",

          images: [
            {
              description: "Description for image 1 here",
              url: "https://media-hosting-bucket.s3.us-west-2.amazonaws.com/zach-taylor/18-plus/sunday.jpg",
            },
            {
              description: "Description for image 2 here",
              url: "https://media-hosting-bucket.s3.us-west-2.amazonaws.com/zach-taylor/18-plus/sunday.jpg",
            },
          ],
        },
        {
          title: "Pill Baby",
          year: "2018",
          description: "Description of piece Fuzzy Seat here",

          images: [
            {
              description: "Description for image 1 here",
              url: "https://media-hosting-bucket.s3.us-west-2.amazonaws.com/zach-taylor/18-plus/pill-baby.jpg",
            },
            {
              description: "Description for image 2 here",
              url: "https://media-hosting-bucket.s3.us-west-2.amazonaws.com/zach-taylor/18-plus/pill-baby.jpg",
            },
          ],
        },
        {
          title: "Open",
          year: "2018",
          description: "Description of piece Fuzzy Seat here",

          images: [
            {
              description: "Description for image 1 here",
              url: "https://media-hosting-bucket.s3.us-west-2.amazonaws.com/zach-taylor/18-plus/open.jpg",
            },
            {
              description: "Description for image 2 here",
              url: "https://media-hosting-bucket.s3.us-west-2.amazonaws.com/zach-taylor/18-plus/open.jpg",
            },
          ],
        },
        {
          title: "Magic 8-Ball",
          year: "2018",
          description: "Description of piece Fuzzy Seat here",

          images: [
            {
              description: "Description for image 1 here",
              url: "https://media-hosting-bucket.s3.us-west-2.amazonaws.com/zach-taylor/18-plus/magic-8-ball.jpg",
            },
            {
              description: "Description for image 2 here",
              url: "https://media-hosting-bucket.s3.us-west-2.amazonaws.com/zach-taylor/18-plus/magic-8-ball.jpg",
            },
          ],
        },
      ],
    },
    {
      title: "Bill Posters",
      description: `Bill Posters is Innocent is an exploration of brutalist graphic design. Inspired by underground music posters, it attempts to mimic the urban bill posting design asthetic.`,
      pieces: [
        {
          title: "Poultry Bigots",
          year: "2021",
          description: "Description of piece Fuzzy Seat here",

          images: [
            {
              description: "Description for image here",
              url: "https://media-hosting-bucket.s3.us-west-2.amazonaws.com/zach-taylor/bill-posters/Slurpee%2BBandit.jpg",
            },
            {
              description: "Description for image here",
              url: "https://media-hosting-bucket.s3.us-west-2.amazonaws.com/zach-taylor/bill-posters/Slurpee%2BBandit.jpg",
            },
          ],
        },
        {
          title: "Tonka Tonic",
          year: "2021",
          description: "Description of piece Fuzzy Seat here",

          images: [
            {
              description: "Description for image 1 here",
              url: "https://media-hosting-bucket.s3.us-west-2.amazonaws.com/zach-taylor/bill-posters/TONKA%2BTONIC.jpg",
            },
            {
              description: "Description for image 2 here",
              url: "https://media-hosting-bucket.s3.us-west-2.amazonaws.com/zach-taylor/bill-posters/TONKA%2BTONIC.jpg",
            },
          ],
        },
        {
          title: "Xyxal Kings",
          year: "2021",
          description: "Description of piece Fuzzy Seat here",

          images: [
            {
              description: "Description for image 1 here",
              url: "https://media-hosting-bucket.s3.us-west-2.amazonaws.com/zach-taylor/bill-posters/Pigeon%2BFeeders.jpg",
            },
            {
              description: "Description for image 2 here",
              url: "https://media-hosting-bucket.s3.us-west-2.amazonaws.com/zach-taylor/bill-posters/Pigeon%2BFeeders.jpg",
            },
          ],
        },
        {
          title: "Have You Seen This Boy?",
          year: "2022",
          description: "Description of piece Fuzzy Seat here",

          images: [
            {
              description: "Description for image 1 here",
              url: "https://media-hosting-bucket.s3.us-west-2.amazonaws.com/zach-taylor/bill-posters/Missing_Photocopy%2BEdit.jpg",
            },
            {
              description: "Description for image 2 here",
              url: "https://media-hosting-bucket.s3.us-west-2.amazonaws.com/zach-taylor/bill-posters/Missing_Photocopy%2BEdit.jpg",
            },
          ],
        },
        {
          title: "Skull Fucking Melons",
          year: "2021",
          description: "Description of piece Fuzzy Seat here",

          images: [
            {
              description: "Description for image 1 here",
              url: "https://media-hosting-bucket.s3.us-west-2.amazonaws.com/zach-taylor/bill-posters/SKULL%2BFUCKING%2BMIGRANES.jpg",
            },
            {
              description: "Description for image 2 here",
              url: "https://media-hosting-bucket.s3.us-west-2.amazonaws.com/zach-taylor/bill-posters/SKULL%2BFUCKING%2BMIGRANES.jpg",
            },
          ],
        },
      ],
    },
    {
      title: "Rubberneck",
      description: `Rubberneck is an exploration of masculinity. Inspired by the rebel cliché, these photographs represent the alien persona of the human who rejects established norms and conventions, has been rejected by society, and has their-selves as the center of their own existence. These characters are cinematic icons depicting free spirit derived from angst. Reminiscent of naive illusion, they are a depiction of romantic heroism.`,
      pieces: [
        {
          title: "Fur Sasquatch",
          year: "2019",
          description: "Description of piece Fuzzy Seat here",

          images: [
            {
              description: "Description for image here",
              url: "https://media-hosting-bucket.s3.us-west-2.amazonaws.com/zach-taylor/rubberneck/fur-sasquatch.jpg",
            },
            {
              description: "Description for image here",
              url: "https://media-hosting-bucket.s3.us-west-2.amazonaws.com/zach-taylor/rubberneck/fur-sasquatch.jpg",
            },
          ],
        },
        {
          title: "Inner Dialogue",
          year: "2016",
          description: "Description of piece Fuzzy Seat here",

          images: [
            {
              description: "Description for image 1 here",
              url: "https://media-hosting-bucket.s3.us-west-2.amazonaws.com/zach-taylor/rubberneck/inner-dialogue.jpg",
            },
            {
              description: "Description for image 2 here",
              url: "https://media-hosting-bucket.s3.us-west-2.amazonaws.com/zach-taylor/rubberneck/inner-dialogue.jpg",
            },
          ],
        },
        {
          title: "Flower Head",
          year: "2018",
          description: "Description of piece Fuzzy Seat here",

          images: [
            {
              description: "Description for image 1 here",
              url: "https://media-hosting-bucket.s3.us-west-2.amazonaws.com/zach-taylor/rubberneck/flower-head.jpg",
            },
            {
              description: "Description for image 2 here",
              url: "https://media-hosting-bucket.s3.us-west-2.amazonaws.com/zach-taylor/rubberneck/flower-head.jpg",
            },
          ],
        },
      ],
    },
    {
      title: "Backslider",
      description: `Backslider by definition means to lapse morally or in the practice of religion or to revert to a worse condition. These images are representative of a Catholic childhood. The fourteen images mimicking the fourteen stations of the cross, symbolize my falling out of religion.`,
      pieces: [
        {
          title: "Backslider 1",
          year: "2022",
          description: "Description of piece Fuzzy Seat here",

          images: [
            {
              description: "Description for image here",
              url: "https://media-hosting-bucket.s3.us-west-2.amazonaws.com/zach-taylor/backslider/backslider-1.jpg",
            },
            {
              description: "Description for image here",
              url: "https://media-hosting-bucket.s3.us-west-2.amazonaws.com/zach-taylor/backslider/backslider-1.jpg",
            },
          ],
        },
        {
          title: "Backslider 2",
          year: "2021",
          description: "Description of piece Fuzzy Seat here",

          images: [
            {
              description: "Description for image 1 here",
              url: "https://media-hosting-bucket.s3.us-west-2.amazonaws.com/zach-taylor/backslider/backslider-2.jpg",
            },
            {
              description: "Description for image 2 here",
              url: "https://media-hosting-bucket.s3.us-west-2.amazonaws.com/zach-taylor/backslider/backslider-2.jpg",
            },
          ],
        },
        {
          title: "Backslider 3",
          year: "2021",
          description: "Description of piece Fuzzy Seat here",

          images: [
            {
              description: "Description for image 1 here",
              url: "https://media-hosting-bucket.s3.us-west-2.amazonaws.com/zach-taylor/backslider/backslider-3.jpg",
            },
            {
              description: "Description for image 2 here",
              url: "https://media-hosting-bucket.s3.us-west-2.amazonaws.com/zach-taylor/backslider/backslider-3.jpg",
            },
          ],
        },
        {
          title: "Backslider 4",
          year: "2022",
          description: "Description of piece Fuzzy Seat here",

          images: [
            {
              description: "Description for image 1 here",
              url: "https://media-hosting-bucket.s3.us-west-2.amazonaws.com/zach-taylor/backslider/backslider-4.jpg",
            },
            {
              description: "Description for image 2 here",
              url: "https://media-hosting-bucket.s3.us-west-2.amazonaws.com/zach-taylor/backslider/backslider-4.jpg",
            },
          ],
        },
        {
          title: "Backslider 5",
          year: "2021",
          description: "Description of piece Fuzzy Seat here",

          images: [
            {
              description: "Description for image 1 here",
              url: "https://media-hosting-bucket.s3.us-west-2.amazonaws.com/zach-taylor/backslider/backslider-5.jpg",
            },
            {
              description: "Description for image 2 here",
              url: "https://media-hosting-bucket.s3.us-west-2.amazonaws.com/zach-taylor/backslider/backslider-5.jpg",
            },
          ],
        },
        {
          title: "Backslider 6",
          year: "2021",
          description: "Description of piece Fuzzy Seat here",

          images: [
            {
              description: "Description for image 1 here",
              url: "https://media-hosting-bucket.s3.us-west-2.amazonaws.com/zach-taylor/backslider/backslider-6.jpg",
            },
            {
              description: "Description for image 2 here",
              url: "https://media-hosting-bucket.s3.us-west-2.amazonaws.com/zach-taylor/backslider/backslider-6.jpg",
            },
          ],
        },
        {
          title: "Backslider 7",
          year: "2021",
          description: "Description of piece Fuzzy Seat here",

          images: [
            {
              description: "Description for image 1 here",
              url: "https://media-hosting-bucket.s3.us-west-2.amazonaws.com/zach-taylor/backslider/backslider-7.jpg",
            },
            {
              description: "Description for image 2 here",
              url: "https://media-hosting-bucket.s3.us-west-2.amazonaws.com/zach-taylor/backslider/backslider-7.jpg",
            },
          ],
        },
      ],
    },
  ],
};
