import React from "react";
import "./UpcomingEvents.css";
import Nav from "../Nav/Nav";

const UpcomingEvents = () => {
  return (
    <div className="events-container">
      <Nav />
      <text className="events-text">ZACKARY TAYLOR ART MOTHERFUCKER</text>
    </div>
  );
};

export default UpcomingEvents;
