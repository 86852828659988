import React, { useEffect, useRef, useState } from "react";
import "./Portfolio.css";
import Nav from "../Nav/Nav";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
// import ReactImageMagnify from "react-image-magnify";
// import Zoom from "react-medium-image-zoom";
// import "react-medium-image-zoom/dist/styles.css";
import {
  MdChevronLeft,
  MdChevronRight,
  MdArrowDownward,
  MdArrowUpward,
  MdCircle,
} from "react-icons/md";
// import HorizontalSlider from "react-horizontal-slider";
import galleryData from "../../util/galleryData";
import PortfolioItemAbout from "./PortfolioItemAbout";
import Footer from "../Footer/Footer";
import PortfolioFullImage from "./PortfolioFullImage";
import { Link, useSearchParams } from "react-router-dom";
const Portfolio = () => {
  const collections = galleryData.collections;
  // const [collections, setCollections] = galleryData.collections;
  const [searchParams, setSearchParams] = useSearchParams();
  const collectionIdParam = searchParams.get("collection");
  const pieceIdParam = searchParams.get("piece");
  let currCollectionIdParam = collectionIdParam
    ? parseInt(collectionIdParam)
    : 0;
  let currPieceIdParam = pieceIdParam ? parseInt(pieceIdParam) : 0;
  const [collectionIndex, setCollectionIndex] = useState(currCollectionIdParam);
  const [pieceIndex, setPieceIndex] = useState(currPieceIdParam);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [fullSizeImageVisible, setFullSizeImageVisible] = useState(false);

  // useEffect(() => {
  //   let cip = searchParams.get("collection");
  //   let pip = searchParams.get("piece");
  //   console.log("search params:", cip, pip);
  //   if (cip) {
  //     setCollectionIndex(cip);
  //   }
  //   if (pip) {
  //     setPieceIndex(pip);
  //   }
  // }, []);

  useEffect(() => {
    console.log("collection index:", collectionIndex);
    console.log("piece index:", pieceIndex);
  }, [collectionIndex, pieceIndex]);

  const collectionScrollRef = useRef(null);
  const piecesScrollRef = useRef(null);

  console.log(
    "gallery data:",
    JSON.stringify(galleryData.collections[0].title)
  );

  // const currImageRef = useRef(
  //   collections[collectionIndex].pieces[pieceIndex].images[currentImageIndex]
  //     .url
  // );

  // useEffect(() => {
  //   currImageRef.current =
  //     collections[collectionIndex].pieces[pieceIndex].images[
  //       currentImageIndex
  //     ].url;
  // }, [pieceIndex, collectionIndex]);

  const handleCollectionClick = (collectionId) => {
    setCollectionIndex(collectionId);
    setPieceIndex(0);
  };

  const handlePieceClick = (pieceId) => {
    setPieceIndex(pieceId);
  };

  const scroll = (scrollOffset, currentRef) => {
    scrollOffset = scrollOffset < 0 ? -150 : 150;
    // currentRef.current.scrollLeft += scrollOffset;
  };

  const handlePieceRightClick = () => {
    if (pieceIndex + 1 >= collections[collectionIndex].pieces.length) {
      setPieceIndex(0);
      // piecesScrollRef.current.scrollLeft += -10000;
    } else {
      // scroll(20, piecesScrollRef);
      setPieceIndex(pieceIndex + 1);
    }
  };
  const handlePieceLeftClick = () => {
    if (pieceIndex - 1 < 0) {
      setPieceIndex(collections[collectionIndex].pieces.length - 1);
      // piecesScrollRef.current.scrollLeft += 10000;
    } else {
      // scroll(-20, piecesScrollRef);
      setPieceIndex(pieceIndex - 1);
    }
  };

  const handleCollectionRightClick = () => {
    if (collectionIndex + 1 >= collections.length) {
      setCollectionIndex(0);
      setPieceIndex(0);
      // collectionScrollRef.current.scrollLeft += -10000;
    } else {
      // scroll(20, collectionScrollRef);
      setCollectionIndex(collectionIndex + 1);
      setPieceIndex(0);
      // piecesScrollRef.current.scrollLeft += -10000;
    }
  };
  const handleCollectionLeftClick = () => {
    if (collectionIndex - 1 < 0) {
      setCollectionIndex(collections.length - 1);
      setPieceIndex(0);
      // piecesScrollRef.current.scrollLeft += -10000;
      // collectionScrollRef.current.scrollLeft += 10000;
    } else {
      setCollectionIndex(collectionIndex - 1);
      setPieceIndex(0);
    }
  };

  return (
    <div className="portfolio-page">
      <Nav />
      <div className="portfolio-container">
        <div className="collection-piece-container">
          {/* <h2 className="collection-header">Series</h2> */}
          <h2 className="proto-title font-face-gm">Series</h2>

          <div className="main-container">
            <MdChevronLeft
              size={"30px"}
              onClick={() => handleCollectionLeftClick()}
              className="direction-arrow-btn"
            />
            <div className="class-col" ref={collectionScrollRef}>
              {/* <span className={"collection-title"}> */}
              <span className={""}>{collections[collectionIndex].title}</span>
            </div>
            <MdChevronRight
              size={"30px"}
              onClick={() => handleCollectionRightClick()}
              className="direction-arrow-btn"
            />
          </div>
        </div>

        <div className="carousel-container">
          <Carousel
            dynamicHeight={true}
            showArrows={false}
            showIndicators={true}
            showStatus={false}
            swipeable={true}
            showThumbs={true}
            thumbWidth={60}
            renderIndicator={(onClickHandler, isSelected, index, label) => {
              const defStyle = {
                // marginTop: 5,
                marginLeft: 7,
                marginRight: 7,
                color: "lightgray",
                cursor: "pointer",
              };
              const style = isSelected
                ? { ...defStyle, color: "white" }
                : { ...defStyle, color: "grey" };
              return (
                <span
                  style={style}
                  onClick={onClickHandler}
                  onKeyDown={onClickHandler}
                  value={index}
                  key={index}
                  role="button"
                  tabIndex={0}
                  aria-label={`${label} ${index + 1}`}
                >
                  <MdCircle
                    size={"17px"}
                    // onClick={() => handlePieceLeftClick()}
                    // className="direction-arrow-btn"
                  />
                </span>
              );
            }}
          >
            {collections[collectionIndex].pieces[pieceIndex].images.map(
              (item, index) => (
                <div>
                  <Link
                    to="image"
                    state={{
                      img: item.url,
                      collectionId: collectionIndex,
                      pieceId: pieceIndex,
                      pieceTitle:
                        collections[collectionIndex].pieces[pieceIndex].title,
                    }}
                    style={{ zIndex: 1000, display: "block", marginBottom: 40 }}
                  >
                    <img
                      src={item.url}
                      style={{ pointerEvents: "none" }}
                      alt="art piece"
                    />
                  </Link>
                </div>
              )
            )}
          </Carousel>
        </div>

        <div className="collection-piece-container">
          {/* <h2 className="collection-header">Title</h2> */}
          <h2 className="proto-title font-face-gm">Title</h2>

          <div className="main-container">
            <MdChevronLeft
              size={"30px"}
              onClick={() => handlePieceLeftClick()}
              className="direction-arrow-btn"
            />
            <div className="class-col" ref={piecesScrollRef}>
              {/* <span className={"collection-title"}> */}
              <span className={"font-face-gm"}>
                {collections[collectionIndex].pieces[pieceIndex].title}
              </span>
            </div>
            <MdChevronRight
              size={"30px"}
              onClick={() => handlePieceRightClick()}
              className="direction-arrow-btn"
            />
          </div>
        </div>

        <PortfolioItemAbout
          collectionTitle={collections[collectionIndex].title}
          collectionDescription={collections[collectionIndex].description}
          pieceTitle={collections[collectionIndex].pieces[pieceIndex].title}
          pieceDescription={
            collections[collectionIndex].pieces[pieceIndex].description
          }
          pieceYear={collections[collectionIndex].pieces[pieceIndex].year}
          handleCollectionLeftClick={handleCollectionLeftClick}
          collectionScrollRef={collectionScrollRef}
          collections={collections}
          collectionIndex={collectionIndex}
          handleCollectionRightClick={handleCollectionRightClick}
          handlePieceClick={handlePieceClick}
        />
      </div>
      <Footer />
    </div>
  );
};

export default Portfolio;
