import React, { useCallback, useEffect, useRef, useState } from "react";
import "./Portfolio.css";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import ReactImageMagnify from "react-image-magnify";

import watchImg687 from "./images/wristwatch_687.jpg";
import watchImg1200 from "./images/wristwatch_1200.jpg";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import {
  MdChevronLeft,
  MdChevronRight,
  MdArrowDownward,
  MdArrowUpward,
} from "react-icons/md";
function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}
const PortfolioFullImage = () => {
  const location = useLocation();
  const imageUrl = location.state.img;
  const { collectionId, pieceId, pieceTitle } = location.state;

  // collectionId: collectionIndex,
  // pieceId: pieceIndex,
  // pieceTitle:
  //   const [searchParams, setSearchParams] = useSearchParams();
  //   const imageUrl = searchParams.get("img");

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  const [imageHeight, setImageHeight] = useState(null);
  const [imageWidth, setImageWidth] = useState(null);
  const [imageRatio, setImageRatio] = useState(null);
  const [isMobile, setIsMobile] = useState(true);

  const [imageSource, setImageSource] = useState(null);

  const imageMeasurements = useCallback((image) => {
    if (image !== null) {
      setImageWidth(image.naturalWidth);
      setImageHeight(image.naturalHeight);
      setImageRatio(image.naturalHeight / image.naturalWidth);
    }
  }, []);

  useEffect(() => {
    console.log("image dimensions:", imageHeight, imageWidth, imageRatio);
  }, [imageHeight, imageWidth, imageRatio]);

  useEffect(() => {
    console.log(
      "windowDimensions:",
      windowDimensions.height,
      windowDimensions.width
    );
    if (windowDimensions.width > 800) {
      setIsMobile(false);
    }
  }, [windowDimensions]);

  return (
    <div>
      <div
        className="portfolio-full-image-container fluid"
        //   style={isVisible ? { display: "block" } : { display: "none" }}
      >
        <img
          ref={imageMeasurements}
          src={imageUrl}
          style={{ display: "none", pointerEvents: "none" }}
        />

        <div className="fluid__image-container font-face-gm">
          <div
            style={{
              padding: 5,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Link
              classname="font-face-gm"
              to={`/portfolio?collection=${collectionId}&piece=${pieceId}`}
              style={{
                display: "flex",
                textDecoration: "none",
                color: "white",
                border: "2px solid white",
                justifyContent: "space-around",
                alignItems: "center",
                width: "70px",
                padding: "3px",
                paddingRight: "10px",
                margin: "6px",
                // fontFamily: "Cygnito Mono",
                fontWeight: "600",
                fontSize: "15px",
              }}
            >
              <MdChevronLeft size={30} />
              <span>Back</span>
            </Link>
            <h1 classname="font-face-gm" style={{ color: "white" }}>
              {pieceTitle}
            </h1>
          </div>
          <ReactImageMagnify
            {...{
              smallImage: {
                alt: "Wristwatch by Ted Baker London",
                //   isFluidWidth: true,
                src: imageUrl,
                width: isMobile
                  ? windowDimensions.width
                  : windowDimensions.width / 2,
                height: isMobile
                  ? windowDimensions.width * imageRatio
                  : (windowDimensions.width / 2) * imageRatio,
              },
              largeImage: {
                src: imageUrl,
                width: 1200,
                height: 1200 * imageRatio,
              },
            }}
          />
          <p style={{ color: "gray", textAlign: "center" }}>
            {isMobile ? "Hold to Zoom" : "Hover to Zoom"}
          </p>
        </div>
      </div>
    </div>
  );
};

export default PortfolioFullImage;
