import React from "react";
import ReactDOM from "react-dom";

// import ReactDOM from "react-dom/client";
import "./resources/CygnitoMono.otf";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Bio from "./components/Bio/Bio";
import UpcomingEvents from "./components/UpcomingEvents/UpcomingEvents";
import GalleryShows from "./components/GalleryShows/GalleryShows";
import Contact from "./components/Contact/Contact";
import Portfolio from "./components/Portfolio/Portfolio";
import PieceImage from "./components/PieceImage/PieceImage";
import PortfolioFullImage from "./components/Portfolio/PortfolioFullImage";
import About from "./components/About/About";

// const root = ReactDOM.createRoot(document.getElementById("root"));
ReactDOM.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<App />} />
      <Route path="/portfolio" element={<Portfolio />} />
      <Route path="/bio" element={<Bio />} />
      <Route path="/about" element={<About />} />

      <Route path="/upcomingevents" element={<UpcomingEvents />} />
      <Route path="/galleryshows" element={<GalleryShows />} />
      <Route path="/contact" element={<Contact />} />
      <Route
        path="/portfolio/image"
        element={<PortfolioFullImage img={""} />}
      />
      <Route path="/image" element={<PortfolioFullImage img={""} />} />
      {/* <Route path="contact" element={<Expenses />} />
      <Route path="art" element={<Invoices />} /> */}
    </Routes>
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
