import React from "react";
import "./GalleryShows.css";
import Nav from "../Nav/Nav";

const GalleryShows = () => {
  return (
    <div className="gallery-container">
      <Nav />
    </div>
  );
};

export default GalleryShows;
