import React from "react";
import "./Bio.css";
import Nav from "../Nav/Nav";
import bioPic from "../../resources/zack.jpg";

const Bio = () => {
  return (
    <div className="bio-main-container">
      <Nav />
      <div className="bio-container">
        <div className="bio-subcontainer">
          <img className="bio-pic" src={bioPic} />
        </div>
        <div className="bio-subcontainer special">
          <text className="bio-text">
            Zach Taylor was born in Las Vegas, NV where he lived for eighteen
            years of his childhood. He is a photographer and mixed-media artist
            who employs a pop art surrealist style. For years he has constructed
            fantasy worlds that discuss power, dominance, and wealth as
            ingrained human desires. Mainly exploring the topic of masculinity
            in a patriarchal world, his work challenges societal norms of gender
            defined privilege. Visually, his work represents his childhood
            subconscious; a vault of sexually charged advertisements. <br></br>{" "}
            Graduating Magna Cum Laude in 2018 from DePauw University, IN, he
            received a BA in both Studio Art and Creative Writing. There he
            received numerous awards including the Fritz Smith Memorial Art
            Award and the Efroymson Summer Research Grant. While attending his
            university, he worked as the digital photography and film studio
            assistant, as well as the graphic designer for the theater
            department.
            <br></br>
            Currently living in Santa Fe, NM, he works for the Santa Fe
            Photographic Workshops. There he is employed as an educator and
            assistant. Working along side established photographers, he helps
            guide students through the digital photographic process including
            printing, editing, and organization. On the side of his occupation,
            he continues to create art and freelance. <br></br>During his time
            in New Mexico, he has exhibited work in galleries including
            Ellsworth Gallery and Gold Leaf Gallery. He is currently a mentor
            for the Monte del Sol Charter School, and has helped create a
            monthly critique group of local artists.
          </text>
        </div>
      </div>
    </div>
  );
};

export default Bio;
