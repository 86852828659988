const checkValidEmail = (email) => {
  console.log(email);
  const re =
    // eslint-disable-next-line
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

const checkValidName = (message) => {
  if (message === "" || message === null || message === undefined) {
    return false;
  } else {
    return true;
  }
};

export { checkValidEmail, checkValidName };
